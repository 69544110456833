.badge-pending {
    color: #f4484a;
    background-color: rgba(244, 72, 74, 0.2);
    padding: 2px 10px;
    border-radius: 5px;
    width: fit-content;
}

.badge-done {
    color: #2cb120;
    background-color: rgba(44, 177, 32, 0.2);
    padding: 2px 10px;
    border-radius: 5px;
    width: fit-content;
}
