.card {
    border: none;
    border-radius: 1.5rem;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);

    &.card-summary {
        .card-body {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 12px;

            .card-icon {
                width: min-content;
                height: min-content;
                padding: 12px;
                background-color: rgba(map-get($edu-primary, "default"), .12);
                border-radius: 50%;
            }

            .card-data {
                flex: 1 1 auto;

                .card-title {
                    font-size: 14px;
                    line-height: 20px;
                    color: $gray-700;
                    font-weight: $font-weight-normal;
                }
            }

            .card-subtitle {
                flex: 0 0 100%;
                max-width: 100%;
                font-size: 14px;
                line-height: 20px;
                color: $gray-700;
            }
        }        
    }

    &.card-profile {

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 50%;
            background-image: url("https://assets.eduku.io/misc/profile_bg_img.svg");
            background-color: map-get($edu-primary, "default");
            background-repeat: repeat-x;
            background-size: contain;
            border-top-left-radius: 1.5rem;
            border-top-right-radius: 1.5rem;
        }

        .card-body {
            display: flex;
            align-items: center;
            gap: 20px;

            .card-picture {
                z-index: 1;
                border-radius: 50em;                
                background-color: $white;
                padding: 8px;
            }

            .card-info {
                flex: 1 1 auto;
                margin-top: 100px; // need to change with more responsive approach later

                .card-name {
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: $font-weight-bold;
                    color: $black;
                    margin-bottom: .5rem;
                }

                .card-data {
                    font-size: 16px;
                    line-height: 24px;
                    color: $gray-700;
                }
            }
        }
        // background: linear-gradient(to bottom, map-get($edu-primary, "default") 50%, white 0%);
    }

    &.card-profile-short {
        .card-body {
            text-align: center;

            .card-name {
                font-size: 16px;
                line-height: 24px;
                color: $black;
                font-weight: $font-weight-bold;
                margin-top: .5rem;
                margin-bottom: .5rem;
            }

            .card-data {
                color: $gray-700;
                margin-bottom: .5rem;                
            }
        }
    }
}

.card-title {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 28px;
    color: $black;
    font-weight: $font-weight-bold;
}
