h1, .h1 { 
    font-size: 34px;
    line-height: 48px;
}
h2, .h2 { 
    font-size: 28px;
    line-height: 42px;
}
h3, .h3 { 
    font-size: 24px;
    line-height: 34px;
}