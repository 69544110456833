.form-group {
    label {
        font-size: 16px;
        font-weight: $font-weight-bold;
        color: $black;
    }
}

.form-control {
    &:hover:not(:disabled):not(.is-valid):not(.is-invalid) {
        border-color: map-get($edu-primary, "default");
    }

    &:disabled,
    &[readonly] {
        border-color: $input-disabled-bg;
    }    
}

