.font-sm {
    font-size: 12px !important;
    line-height: 16px !important;
}

.font-md {
    font-size: 14px !important;
    line-height: 20px !important;
}

.font-lg {
    font-size: 16px !important;
    line-height: 24px !important;
}

.font-xl {
    font-size: 20px !important;
    line-height: 28px !important;
}