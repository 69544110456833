.toast-container {
    .ngx-toastr {
        background-position: left 0.75rem top 15px;

        .toast-title {
            font-size: 16px;
            font-weight: 700;
        }
        .toast-close-button {
            font-weight: 400;
            text-shadow: none;
            color: inherit;
            opacity: 1;
        }
        .toast-close-button:hover,
        .toast-close-button:focus {
            color: inherit;
            opacity: 1;
        }
    }
}

.toast-success {
    background-image: url(svg-encode(
        svg-factory(
            $white,
            '0 0 24 24',
            'M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm-1.999 14.413-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 1.414-6.706 6.706z'
        )
    ));
    color: $white;
    background-color: theme-color('success');
    border: 1px solid theme-color('success');
}
.toast-error {
    background-image: url(svg-encode(
        svg-factory(
            $white,
            '0 0 24 24',
            'M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm4.207 12.793-1.414 1.414L12 13.414l-2.793 2.793-1.414-1.414L10.586 12 7.793 9.207l1.414-1.414L12 10.586l2.793-2.793 1.414 1.414L13.414 12l2.793 2.793z'
        )
    ));
    color: $white;
    background-color: theme-color('danger');
    border: 1px solid theme-color('danger');
}
.toast-info {
    background-image: url(svg-encode(
        svg-factory(
            $white,
            '0 0 24 24',
            'M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z'
        )
    ));
    color: $white;
    background-color: theme-color('info');
    border: 1px solid theme-color('info');
}
.toast-warning {
    background-image: url(svg-encode(
        svg-factory(
            $white,
            '0 0 24 24',
            'M12.884 2.532c-.346-.654-1.422-.654-1.768 0l-9 17A.999.999 0 0 0 3 21h18a.998.998 0 0 0 .883-1.467L12.884 2.532zM13 18h-2v-2h2v2zm-2-4V9h2l.001 5H11z'
        )
    ));
    color: $white;
    background-color: theme-color('warning');
    border: 1px solid theme-color('warning');
}