.empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    > :not(:last-child) { margin-bottom: 1rem; }

    .empty-title {
        font-size: 20px;
        line-height: 28px;
        color: $black;
        font-weight: $font-weight-bold;
    }

    .empty-message {
        color: $gray-700;
    }
}