.nav-tabs {
    align-items: flex-end;
    border-bottom: 0;

    .nav-link {
        position: relative;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid $nav-tabs-border-color;
        font-size: 16px;
        line-height: 24px;
        font-weight: $font-weight-medium;
        color: $gray-500 !important;

        &:hover {
            color: map-get($edu-primary, "dark") !important;
            font-weight: $font-weight-bold;
        }

        &.disabled {
            color: $gray-400 !important;
            border-color: $gray-300;
        }

        i {
            vertical-align: middle;
        }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
        color: map-get($edu-primary, "dark") !important;
        font-weight: $font-weight-bold;
        background-color: transparent !important;
        border-bottom: none;
        padding-bottom: calc(.5rem + 4px);
        
        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 4px;
            width: 100%;
            background-color: #00AE7D;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
    }

    &.nav-stacked {
        align-items: normal;

        .nav-link {
            border-left: 1px solid $nav-tabs-border-color;
            border-bottom: none;
        }

        .nav-link.active,
        .nav-item.show .nav-link {
            border-left: none;
            padding-left: calc(1rem + 4px);
            padding-bottom: .5rem;
            
            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 100%;
                width: 4px;
                background-color: #00AE7D;
                border-top-left-radius: 0;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }            
        }
    }
}

.tab-content {
    border: none;
}