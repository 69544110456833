.form-control {
    &[bsdatepicker], &[bsdaterangepicker] {
        background-position: right 10px center;
        background-repeat: no-repeat;
        background-image: url(svg-encode(
            svg-factory(
                $gray-700,
                '0 0 24 24',
                'M21 20V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2zM9 18H7v-2h2v2zm0-4H7v-2h2v2zm4 4h-2v-2h2v2zm0-4h-2v-2h2v2zm4 4h-2v-2h2v2zm0-4h-2v-2h2v2zm2-5H5V7h14v2z'
            )
        ));
        padding-right: 44px;
    }

    &:disabled,
    &[readonly] {
        &[bsdatepicker], &[bsdaterangepicker] {
            background-image: url(svg-encode(
                svg-factory(
                    $gray-500,
                    '0 0 24 24',
                    'M21 20V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2zM9 18H7v-2h2v2zm0-4H7v-2h2v2zm4 4h-2v-2h2v2zm0-4h-2v-2h2v2zm4 4h-2v-2h2v2zm0-4h-2v-2h2v2zm2-5H5V7h14v2z'
                )
            ));
        }
    }
}

/* theming */
@each $name, $value in $edu-theme-colors {
    @include edu-dp-theming($name, $value);
}