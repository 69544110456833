.carousel-indicators {
    margin-bottom: 0;

    li {
        border: none;
        border-radius: 50%;
        opacity: 1;
    }

    .active {
        width: 30px;
        border-radius: 10rem;
        background-color: map-get($edu-primary, "default");
    }
}