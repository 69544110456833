.modal-content {
    border-radius: 12px;

    &.modal-confirm {
        .modal-body {
            text-align: center;
            padding-bottom: 0;

            > :not(:first-child) { margin-top: .25rem; }
            > :not(:last-child) { margin-bottom: .25rem; }

            .modal-icon {
                width: min-content;
                height: min-content;
                margin-left: auto;
                margin-right: auto;
                padding: 12px;                
                background-color: transparent;                
                border-radius: 50%;

                i, span {
                    font-size: 36px;
                    vertical-align: middle;
                }

                &.modal-icon-primary {
                    background-color: rgba(map-get($edu-primary, "default"), .12);
                    color: map-get($edu-primary, "default");
                }

                &.modal-icon-secondary {
                    background-color: rgba(map-get($edu-secondary, "default"), .12);
                    color: map-get($edu-secondary, "default");
                }

                &.modal-icon-success {
                    background-color: rgba($success, .12);
                    color: $success;
                }

                &.modal-icon-danger {
                    background-color: rgba($danger, .12);
                    color: $danger;
                }

                &.modal-icon-warning {
                    background-color: rgba($warning, .12);
                    color: $warning;
                }

                &.modal-icon-info {
                    background-color: rgba($info, .12);
                    color: $info;
                }                
            }
        }

        .modal-footer {
            justify-content: center;
        }
    }
}

.modal-title {
    font-size: 16px;
    font-weight: $font-weight-bold;
    line-height: 24px;
    color: $black;
}

.modal-footer {
    border-top: none;
}