.ngx-datatable.bootstrap {
    font-size: 12px;
    .datatable-header {
        background-color: map-get($edu-primary, "dark");
        color: $white;
        font-size: 14px;
        font-weight: 700;
        .datatable-header-cell {
            padding: 14px 16px;
            border: none;
        }
    }
    .datatable-body {
        .datatable-body-row {
            vertical-align: top;
            border-top: 1px solid $gray-300;
            &.datatable-row-even {
                background-color: $white;
            }
            &.datatable-row-odd {
                background-color: $gray-100;
            }            
            .datatable-body-cell {
                padding: 14px 16px;
            }
        }
    }
    .datatable-footer {
        background: transparent;
        color: $gray-800;
        .datatable-pager {
            ul {
              li {
                &:not(.disabled) {
                  &.active,
                  &:hover {
                    a {
                      background-color: map-get($edu-secondary, "default");
                      color: $white;
                      font-weight: 700;
                    }
                  }
                }
              }
            }
            a {              
              color: map-get($edu-secondary, "default");
            }
          }
    }
}