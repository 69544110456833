@mixin edu-button-variant($value, $background: map-get($value, "default"), $border: map-get($value, "default"), $hover-background: map-get($value, "darker"), $hover-border: map-get($value, "darker"), $active-background: map-get($value, "darker"), $active-border: map-get($value, "darker")) {
    color: color-yiq($background);
    background-color: $background;
    border-color: $border;
  
    &:hover {
        color: color-yiq($background);
        background-color: $active-background;
        border-color: $active-border;
    }
  
    &:focus,
    &.focus {
        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows {
            box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($background, .2);
        } @else {
            box-shadow: 0 0 0 $btn-focus-width rgba($background, .2);
        }
    }
  
    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        color: $gray-500;
        background-color: $gray-200;
        border-color: $gray-400;
        box-shadow: none;
        // Remove CSS gradients if they're enabled
        @if $enable-gradients {
            background-image: none;
        }
    }
  
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
        @if $enable-gradients {
            background-image: none; // Remove the gradient for the pressed/active state
        }
        border-color: $active-border;
    
        &:focus {
            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows and $btn-active-box-shadow != none {
                box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($background, .2);
            } @else {
                box-shadow: 0 0 0 $btn-focus-width rgba($background, .2);
            }
        }
    }
}

@mixin edu-button-outline-variant($value, $color: map-get($value, "dark"), $border: map-get($value, "default"), $color-hover: map-get($value, "dark"), $active-background: map-get($value, "light"), $active-border: map-get($value, "dark")) {
    color: $color;
    border-color: $border;
  
    &:hover {
        color: $color-hover;
        background-color: $active-background;
        border-color: $active-border;
    }
  
    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($border, .2);
    }
  
    &.disabled,
    &:disabled {
        color: $gray-500;
        background-color: $gray-200;
        border-color: $gray-400;
        box-shadow: none;
    }
  
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      color: color-yiq($active-background);
      background-color: $active-background;
      border-color: $active-border;
  
      &:focus {
        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows and $btn-active-box-shadow != none {
          box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($border, .2);
        } @else {
          box-shadow: 0 0 0 $btn-focus-width rgba($border, .2);
        }
      }
    }
  }
  