.font-weight-medium { font-weight: $font-weight-medium !important; }

.text-black {
    color: $black !important;
}

@each $color, $value in $grays {
    .text-gray-#{$color} {
        color: $value !important;
    }    
}