.ng-select {
    .ng-select-container {
        border: 1px solid $gray-200;
        border-radius: 8px;

        &:hover {
            border-color: map-get($edu-primary, "default");
        }
    }

    .ng-arrow-wrapper .ng-arrow {
        border-color: $gray-800 transparent transparent;
    }

    .ng-clear-wrapper {
        color: $gray-800;
    }

    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
        border-color: map-get($edu-primary, "default");
        box-shadow: 0 0 0 $btn-focus-width
            rgba(map-get($edu-primary, "default"), 0.2);
    }

    &.ng-select-opened {
        .ng-arrow-wrapper .ng-arrow {
            border-color: transparent transparent $gray-800;
        }
    }

    &.ng-select-disabled {
        .ng-select-container {
            background-color: $gray-200;
            border: 1px solid $gray-200;

            &:hover {
                border-color: $gray-200;
            }
        }

        .ng-arrow-wrapper .ng-arrow {
            border-color: $gray-500 transparent transparent;
        }

        .ng-clear-wrapper {
            color: $gray-500;
        }
    }

    &.ng-select-disabled.ng-select-opened {
        .ng-arrow-wrapper .ng-arrow {
            border-color: transparent transparent $gray-500;
        }
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected.ng-option-marked {
    color: #333;
    background-color: map-get($edu-primary, "medium");
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    color: #333;
    background-color: map-get($edu-primary, "light");
}
