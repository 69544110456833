@mixin edu-dp-theming($name, $value, $color: map-get($value, "default"), $background: map-get($value, "dark"), $border: map-get($value, "dark"), $hover: map-get($value, "darker")) {
    .theme-#{$name} {
        .bs-datepicker-head {
            background-color: $background;
        }

        .btn-today-wrapper, .btn-clear-wrapper {
            .btn-success {
                background-color: $color;
                border-color: $color;
            }
            .btn-success:not(:disabled):not(.disabled):active {
                &:focus {
                    box-shadow: none;
                }
            }
            .btn-success:focus {
                box-shadow: none;
            }
        }

        .btn-today-wrapper, .btn-clear-wrapper {
            .btn-success:not(:disabled):not(.disabled):active {
                background-color: $color;
                border-color: $color;
            }

            .btn-success:hover {
                background-color: $hover;
                border-color: $hover;
            }
        }

        .bs-datepicker-predefined-btns {
            button {
                &.selected {
                    background-color: $color;
                }
            }
        }

        .bs-datepicker-body {
            table {
                td {
                    span.selected,
                    &.selected span,
                    span[class*="select-"]:after,
                    &[class*="select-"] span:after {
                        background-color: $background;
                    }

                    &.week span {
                        color: $background;
                    }

                    &.active-week span:hover {
                        cursor: pointer;
                        background-color: $color;
                        color: $white;
                        opacity: 0.5;
                        transition: 0s;
                    }
                }
            }
        }
    }
}
