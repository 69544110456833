body {
    font-size: 14px;
    line-height: 20px;
}

a {
    cursor: pointer;    

    &:not([href]):not([tabindex]) {
        color: $link-color;
        text-decoration: $link-decoration;

        @include hover-focus {
            color: $link-hover-color;
            text-decoration: $link-hover-decoration;
        }
    }
}