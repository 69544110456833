.img-avatar-sm {
  max-width: 32px;
  height: auto;
  border-radius: 50em;
}

.img-avatar-md {
    max-width: 48px;
    height: auto;
    border-radius: 50em;
}

.img-avatar-lg {
    max-width: 64px;
    height: auto;
    border-radius: 50em;
}

.img-avatar-xl {
    max-width: 80px;
    height: auto;
    border-radius: 50em;
}